// Global packages and components
import { clsx } from 'clsx'
import { getCurrencySymbol, formatPrice, getPriceExclTax } from '~/utils'

// Types
import type { PriceValue } from '~/types'

interface ItemPriceProps {
  price?: PriceValue
  salePrice?: PriceValue | null
  noPriceText?: string
  align?: 'left' | 'right'
  size?: 'sm' | 'md' | 'lg' | 'xl'
  highlight?: boolean
  hasExcluding?: boolean
}

// @NOTE: This component expects price excluding VAT

// Main export
const Price = ({
  price,
  salePrice,
  noPriceText = 'View for price',
  align = 'left',
  size = 'md',
  highlight,
  hasExcluding,
}: ItemPriceProps = {}) => {
  // No price found
  if (!price?.amount)
    return (
      <div
        className={clsx('price', {
          'text-left': align == 'left',
          'text-right': align == 'right',
        })}
      >
        <p
          className={clsx('mb-0 font-bold', {
            'text-lg sm:text-xl': size == 'md',
            'text-xl sm:text-2xl': size == 'lg',
            'text-2xl sm:text-3xl': size == 'xl',
            'text-teal-green': highlight,
          })}
        >
          {noPriceText}
        </p>
      </div>
    )

  // Price found
  const symbol = getCurrencySymbol(price?.currency ?? 'GBP')

  return (
    <div
      className={clsx('price', {
        'text-left': align == 'left',
        'text-right': align == 'right',
      })}
    >
      <p
        className={clsx('mb-0', {
          'text-lg sm:text-xl': size == 'md',
          'text-xl sm:text-2xl': size == 'lg',
          'text-2xl sm:text-3xl': size == 'xl',
          'text-teal-green': highlight,
        })}
      >
        <span className="font-bold">
          {salePrice && salePrice?.amount !== price.amount
            ? symbol + formatPrice(salePrice.amount)
            : symbol + formatPrice(price.amount)}
        </span>
        {salePrice && salePrice?.amount !== price.amount && (
          <>
            &nbsp;
            <span className="text-neutral-500 line-through">
              {symbol + formatPrice(price.amount)}
            </span>
          </>
        )}
        {hasExcluding && (
          <span className="text-[60%] text-neutral-400">
            &nbsp;
            {salePrice && salePrice?.amount !== price.amount
              ? symbol +
                getPriceExclTax(salePrice?.currency, salePrice.amount).toFixed(
                  2
                )
              : symbol +
                getPriceExclTax(price?.currency, price.amount).toFixed(2)}
            &nbsp;ex.VAT
          </span>
        )}
      </p>
    </div>
  )
}

export default Price
